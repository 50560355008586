.glow {
    animation: glow 1.5s infinite;
    box-shadow: inset 3px 3px 6px rgba(255, 0, 0, 0.7);
  }
  
  @keyframes glow {
    0% {
      box-shadow: inset 3px 3px 3px rgba(255, 0, 0, 0.3);
    }
    50% {
      box-shadow: inset 3px 3px 10px rgba(255, 0, 0, 0.8);
    }
    100% {
      box-shadow: inset 3px 3px 3px rgba(255, 0, 0, 0.3);
    }
  }